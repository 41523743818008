<template>
  <div class="navbar-container d-flex content align-items-center">
    <b-link
        class="nav-link"
        @click="toggleVerticalMenuActive"
    >
      <feather-icon
          icon="MenuIcon"
          size="21"
      />
    </b-link>
  </div>
</template>

<script>
import {
  BLink
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
