<template>
  <div class="">
    <div class="d-flex justify-content-between" style="margin-bottom: 15px">
      <p class="page-breadcrumbs-manual" style="margin-bottom: 0px; margin-top: auto; margin-bottom: auto"><span @click="$router.push('/')">Home </span><span class="active">// View Rental Claims</span></p>
      <b-button variant="primary" @click="addNewJob">New Job</b-button>
    </div>
    <b-card>
      <b-form-input
          placeholder="Search"
          debounce="500"
          v-model="filter"
      ></b-form-input>
    </b-card>
    <b-card>
      <div class="d-flex">
        <div class="col-lg-3 d-flex justify-content-between">
          <div class="">
            <p class="rental-total-nbr"><b>{{computedNewClaimsCount}}</b></p>
            <p>New Incoming Claims</p>
          </div>
          <div class="rental-result-line"></div>
        </div>
        <div class="col-lg-3 d-flex justify-content-between" style="margin-left: 15px">
          <div class="">
            <p class="rental-total-nbr"><b class="">{{computedProcessedClaimsCount}}</b></p>
            <p>Processed rental Claims</p>
          </div>
          <div class="rental-result-line"></div>
        </div>
        <div class="col-lg-3" style="margin-left: 15px">
          <p class="rental-total-nbr"><b>{{computedFailedSubClaimsCount}}</b></p>
          <p>Failed Submission</p>
        </div>
      </div>
    </b-card>

    <div class="use-pagination-block-bottom">
      <b-table
          responsive
          hover
          :items="claims"
          :fields="fields"
          :filter="filter"
          :filter-function="filterTable"
          :current-page="claimsCurrentPage"
          :per-page="claimsPerPage"
          @row-clicked="goToClaim"
          @filtered="onFiltered"
      >

        <template v-slot:cell(repairEndDate)="row">
          <span>{{row.item.repairEndDate | formatDate}}</span>
        </template>

        <template v-slot:cell(repairStartDate)="row">
          <span>{{row.item.repairStartDate | formatDate}}</span>
        </template>
      </b-table>
    </div>

    <block-pagination
        :role="'claims'"
        :count="claims.length"
        :countFiltered="filter ? claimsFiltered : claims.length"
        :currentPage="claimsCurrentPage"
        @changedCurrentPage="v => claimsCurrentPage = v"
        :perPage="claimsPerPage"
        @changedPerPage="v => claimsPerPage = v"
    >
    </block-pagination>
  </div>
</template>

<script>
import {BCard, BFormInput, BTable, BButton } from 'bootstrap-vue'
import BlockPagination from '../block-pagination'
import {mapGetters} from 'vuex'
import _ from 'lodash'
import * as dayjs from "dayjs";
export default {
  name: "RentalClaims",
  components: {
    BCard,
    BFormInput,
    BTable,
    BlockPagination,
    BButton,
  },
  data() {
    return {
      filter: '',
      fields: [
        {
          label: 'Ref Nbr',
          key: 'refNbr',
          sortable: true,
        },
        {
          label: 'Repairer Name',
          key: 'repairName',
          sortable: true,
        },
        {
          label: 'Claim Nbr',
          key: 'claimNbr',
          sortable: true,
        },
        {
          label: 'Vch-Rego',
          key: 'rego',
          sortable: true,
        },

        {
          label: 'Owner Name',
          key: 'vehicleOwnerName',
          sortable: true,
        },
        {
          label: 'Phone',
          key: 'vehicleOwnerPhone',
          sortable: true,
        },

        {
          label: 'Repair Start Date',
          key: 'repairStartDate',
          sortable: true,
        },
        {
          label: 'Received Date',
          key: 'repairEndDate',
          sortable: true,
        },
        {
          label: 'BMS',
          key: 'BMS',
          sortable: true,
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          label: 'Action',
          key: 'action',
          sortable: true,
        },
      ],
      claimsFiltered: 0,
      claimsCurrentPage: 1,
      claimsPerPage: 200,
    }
  },
  methods: {
    addNewJob() {
      this.$router.push({
        name: 'claimView',
        params: {
          id: 'new'
        }
      })
    },
    onFiltered(fi) {
      this.claimsFiltered = fi.length;
      this.claimsCurrentPage = 1;
    },
    filterTable(row, filter) {
      let formattedFilter = _.trim(_.toLower(filter))

      if (_.includes(_.trim(_.toLower(row.refNbr)), formattedFilter)) {
        return true
      } else if (_.includes(_.trim(_.toLower(row.repairName)), formattedFilter)) {
        return true
      } else if (_.includes(_.trim(_.toLower(row.claimNbr)), formattedFilter)) {
        return true
      } else if (_.includes(_.trim(_.toLower(row.rego)), formattedFilter)) {
        return true
      } else if (_.includes(_.trim(_.toLower(row.vehicleOwnerName)), formattedFilter)) {
        return true
      } else if (_.includes(_.trim(_.toLower(row.vehicleOwnerPhone)), formattedFilter)) {
        return true
      } else if (_.includes(_.trim(_.toLower(this.formatDate2(row.repairStartDate))), formattedFilter)) {
        return true
      } else if (_.includes(_.trim(_.toLower(this.formatDate2(row.repairEndDate))), formattedFilter)) {
        return true
      } else if (_.includes(_.trim(_.toLower(row.BMS)), formattedFilter)) {
        return true
      } else if (_.includes(_.trim(_.toLower(row.status)), formattedFilter)) {
        return true
      }
    },
    formatDate2(v) {
      if (v) {
        return  dayjs(v).format('DD/MM/YYYY')
      }
      return ''
    },
    goToClaim(item) {
      this.$router.push({
        name: 'claimView',
        params: {
          id: item.id
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      claims: 'claims'
    }),
    computedNewClaimsCount() {
      return _.filter(this.claims, (c) => {
        return c.status == 'active'
      }).length
    },
    computedProcessedClaimsCount() {
      return _.filter(this.claims, (c) => {
        return c.status == 'invoiced' || c.status == 'closed'
      }).length
    },
    computedFailedSubClaimsCount() {
      return _.filter(this.claims, (c) => {
        return c.status == 'cancelled'
      }).length
    },
  }
}
</script>

<style scoped>
.rental-result-line {
  width: 5px;
  height: 90%;
  background-color: #14b4bb;
}
.rental-total-nbr {
  font-size: 25px;
}
.use-pagination-block-bottom {
  min-height: calc(100vh - 380px);
}
</style>
